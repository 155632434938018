.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid white;
}
.footer{

    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
 
}
.Logo-f{
   
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   
  

}
.blur-f1{
    bottom: 0;
    right: 15%;
    width: 20rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
    -webkit-filter: blur(200px);
}
.blur-f2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background:orange;
    -webkit-filter: blur(200px);
}