
.programs_headers{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    justify-content: center;
    text-transform: uppercase;
    font-style: italic;
}
.plan_container{
    margin-top: 4rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.blur-plan1{
    width: 30rem;
    height: 23rem;
 
    left: 0rem;

}
.blur-plan{
    width: 32rem;
    height: 23rem;
    
    right: 0rem;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 5rem;
    width: 12rem;
    margin-top: 2rem;
}
.plan:nth-child(2){
    background-color: var(--caloryCard);
    transform: scale(1.1);
  
}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
    
}
.features{
    display: flex;
    flex-direction: column;
   
    gap: 1rem;
}
.features>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
   
}
.plans>:nth-child(2){
    background-color: var(--orange);  
}
.plans>:nth-child(2)>button{
    color: orange;  
}


