.reason{
    padding: 2rem;
    display: flex;
    gap: 2rem;
}
.left{
    flex: 1 1;
    display: grid;
    background-color: transparent;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows:1fr
}
.right{
    flex:  1 1;
    display: grid;
}
.left>img{
    object-fit: cover;
}
.left>:nth-child(1){
    width: 12rem;
 
    height: 28rem;
}
.left>:nth-child(2){
    width: 14rem;
    
    
    height: 28rem;
}
.left>:nth-child(3){
  
    width: 19rem;
    height: 28rem; 
   
}
.right{
    flex: 1 1;
    text-transform: uppercase;
    gap:1rem;
    display: flex;
    flex-direction: column;
}
.right>span{
    color: var(--orange);
    font-weight: bold;
}
.right>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.details-r>div{
    font-size: 1rem;
    display: flex;
    gap: 1rem;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    
    gap:1rem;
}
.partners>img{
    width: 2.5rem;
}

    

    

   
    
    

   
    
   
