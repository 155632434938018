.programs{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    padding: 0.5rem;
}
.programs_headers{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    justify-content: center;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    display: flex;
    gap: 1rem;
}
.categories{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
}
.categories>:nth-child(1){
    width: 2rem;
    height: 2rem;fill: white;
}
.categories>:nth-child(2){
   font-weight: bold;
   font-size: 1rem;
}
.categories>:nth-child(3){
    font-style: italic;
    font-size: 1rem;
 }
 .join-now{
    display: flex;
    align-items: center;gap: 2rem;
 }
.join-now>img{
    width: 0.2rem;
    height: 1px;

}
.categories:hover{
    background-color: orange;
    cursor: pointer;
}
